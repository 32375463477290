import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {RootState} from "./store";
import {encodedTexture} from "./colorSlice";


export const getColors = createAsyncThunk(
    'colors/list',
    async(credentials, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.get("/api/colors", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)
export interface svgElement {
    type: string
    textureName: string
}
export const getSvgElements = createAsyncThunk<encodedTexture, svgElement>(
    'elements/get',
    async({textureName, type}, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.post("/api/svg/get-elements",{
            type: type,
            name: textureName
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export interface pngContent {
    content: string,
    wall: string,
    floor: string
}

export const convertToPng = createAsyncThunk<Blob, pngContent>(
    'colors/convert',
    async({content, wall, floor}, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.post("/api/png/convert",{
            content,
            wall,
            floor
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob'
        })
            .then(response =>  {
                // Create a blob URL from the response data
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'export.png');
                document.body.appendChild(link);
                // Trigger the click event on the link to start the download
                link.click();
                // Remove the link from the DOM
                document.body.removeChild(link);
                // Return success
                return response.data;
            })
            .catch(error => console.error(error));
    }
)