import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {convertToPng, getColors, getSvgElements} from "./colorActions";
import {Color, ColorButton} from "../Types/Color";
import store from "./store";
import {
    BASE64_PREFIX, ELEVATOR_BACK_WALL,
    ELEVATOR_FLOOR_CONTENT,
    ELEVATOR_LEFT_WALL_CONTENT,
    ELEVATOR_RIGHT_WALL
} from "../Constants/Elevator";
import {Blob} from "node:buffer";

export interface elevator {
    floor?: string
    leftWall?: string
    rightWall?: string
    backWAll?: string
}
export interface colorState {
    colors: Color[]
    currentFloorTexture: string | null
    currentWallsTexture: string | null
    currentFloorName: string | null
    currentWallsName: string | null
    elevator: elevator
    isLoading: boolean
    contentToConvert: string
    export: string
    svgWidth: number
    isConvertLoading: boolean
}

const initialState: colorState = {
    colors: [],
    currentFloorTexture: null,
    currentWallsTexture: null,
    currentFloorName: null,
    currentWallsName: null,
    elevator: {
        floor: BASE64_PREFIX + ELEVATOR_FLOOR_CONTENT,
        leftWall: BASE64_PREFIX + ELEVATOR_LEFT_WALL_CONTENT,
        rightWall: BASE64_PREFIX + ELEVATOR_RIGHT_WALL,
        backWAll: BASE64_PREFIX + ELEVATOR_BACK_WALL,
    },
    isLoading: false,
    contentToConvert: null,
    export: null,
    svgWidth: 800,
    isConvertLoading: false
}

export interface encodedTexture {
    floor?: string
    left?: string
    right?: string
    back?: string
}

export const colorSlice = createSlice({
    name: 'color',
    initialState,
    reducers: {
        setColors: (state, action: PayloadAction<Color[]>) => {
            state.colors = action.payload
        },
        setContentToConvert: (state, action: PayloadAction<string>) => {
            state.contentToConvert = action.payload
        },
        setIsConvertLoading: (state, action: PayloadAction<boolean>) => {
            state.isConvertLoading = action.payload
        },
        initCurrentFloorTexture: (state, action: PayloadAction<string>) => {
            state.currentFloorTexture = action.payload
        },
        initCurrentWallsTexture: (state, action: PayloadAction<string>) => {
            state.currentWallsTexture = action.payload
        },
        setCurrentFloorTexture: (state, action: PayloadAction<ColorButton>) => {
            state.currentFloorTexture = action.payload.imagePath
            state.currentFloorName = action.payload.name
        },
        setCurrentWallsTexture: (state, action: PayloadAction<ColorButton>) => {
            state.currentWallsTexture = action.payload.imagePath
            state.currentWallsName = action.payload.name
        },
        initElevator: (state, action: PayloadAction<string>) => {
            if (action.payload === 'floor') {
                state.elevator = {
                    ...state.elevator,
                    floor : BASE64_PREFIX + ELEVATOR_FLOOR_CONTENT,
                }
            } else {
                state.elevator = {
                    ...state.elevator,
                    leftWall: BASE64_PREFIX + ELEVATOR_LEFT_WALL_CONTENT,
                    rightWall: BASE64_PREFIX + ELEVATOR_RIGHT_WALL,
                    backWAll: BASE64_PREFIX + ELEVATOR_BACK_WALL
                }
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(getColors.pending, (state, action) => {
        })
        builder.addCase(getColors.fulfilled, (state, action) => {
            state.colors = action.payload
        })
        builder.addCase(getColors.rejected, (state, action) => {
        })
        builder.addCase(getSvgElements.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getSvgElements.rejected, (state, action) => {
            state.isLoading = false
        })
        builder.addCase(getSvgElements.fulfilled, (state, action) => {
            if (action.payload.floor) {
                state.elevator.floor = BASE64_PREFIX + action.payload.floor
            } else {
                state.elevator.backWAll = BASE64_PREFIX + action.payload.back
                state.elevator.leftWall = BASE64_PREFIX + action.payload.left
                state.elevator.rightWall = BASE64_PREFIX + action.payload.right
            }
            state.isLoading = false
        })
        builder.addCase(convertToPng.pending, (state, action) => {
            state.isConvertLoading =true
        })
        builder.addCase(convertToPng.rejected, (state, action) => {
            state.isConvertLoading =false
        })
        builder.addCase(convertToPng.fulfilled, (state, action) => {
            state.isConvertLoading =false
        })

    }
})

// Action creators are generated for each case reducer function
export const {
    setContentToConvert,
    initElevator,
    setCurrentFloorTexture,
    setIsConvertLoading,
    setCurrentWallsTexture } = colorSlice.actions

export default colorSlice.reducer